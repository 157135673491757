import React, { Component } from 'react'
import { MenuItem, Menu, Input, Icon } from 'semantic-ui-react'
import './menu.css'
import image from '../assets/Logo.png'


export default class MenuExampleNameProp extends Component {
  state = {}

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    const { activeItem } = this.state

    return (<div class='Mnbr001B'>
      <div>
          < img src={image} alt='Logo' id='Lg-img'/>
       </div>
      <Menu id='Mnbr01'>
        <MenuItem id='textplus'
          name='Home'
          active={activeItem === 'Home'}
          onClick={this.handleItemClick}
        />
        <MenuItem id='textplus'
          name='Job Openings'
          active={activeItem === 'Job Openings'}
          onClick={this.handleItemClick}
        />
        <MenuItem id='textplus'
          name='Departments'
          active={activeItem === 'Departments'}
          onClick={this.handleItemClick}
        />
        <MenuItem id='textplus'
          name='Why work with us?'
          active={activeItem === "Why work with us?"}
          onClick={this.handleItemClick}
        />
         <MenuItem id='textplus'
          name='Our Culture!'
          active={activeItem === "Our Culture!"}
          onClick={this.handleItemClick}
        />
        <MenuItem id='textplus'
          name='Events and Webinar'
          active={activeItem === "Events and Webinar"}
          onClick={this.handleItemClick}
        />
        <MenuItem id='textplus'
          name='FAQs'
          active={activeItem === "FAQs"}
          onClick={this.handleItemClick}
        />
        <MenuItem id='textplus'
          name='Contact us'
          active={activeItem === "Contact us"}
          onClick={this.handleItemClick}
        />
        <MenuItem id='textplus'
          name='Sign in'
          active={activeItem === "Sign in"}
          onClick={this.handleItemClick}
        />
        <MenuItem id='textplus'
          name='Register'
          active={activeItem === "Register"}
          onClick={this.handleItemClick}
        />
              <Input icon='search' placeholder='Search Jobs!' id='Srch'/>
              <Icon name='th' size='large' id='icn-001A' />
      </Menu>
       </div>
    )
  }
}