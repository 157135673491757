import logo from './assets/Logo.png';
import './App.css';
import Menu from './components/menu';


function App() {
  return (<>
  <Menu/>
  
 </>

  );
}

export default App;
